<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col v-for="card in cards" :key="card.id" :cols="size(card.cols)" class="ml-auto">
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="card.class ? card.class : 'justify-center headline primary--text font-weight-bold'"
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-html="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar v-if="!$vuetify.breakpoint.xs && card.icon" class="ma-1" size="150" tile>
                <v-icon size="70" color="primary">{{card.icon}}</v-icon>
              </v-avatar>
            </div>
            <LightBox
              :startAt="startAt"
              :media="card.media"
              v-if="showlightbox && card.media"
              @onClosed="onLightBoxToggle($event)"
            ></LightBox>
            <v-img
              v-if="card.image"
              :src="card.image"
              contain
              height="250"
              @click="startAt = card.id -4 , showlightbox = !showlightbox"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";
import LightBox from "vue-image-lightbox";

export default {
  components: { BackBtn, LightBox },
  data: () => ({
    height: window.innerHeight - 200,
    startAt: 0,
    showlightbox: false,
    cards: [
      {
        id: 1,
        name: "Quick Inspections",
        icon: undefined,
        blurb:
          "Directly performed on the phone where deviations are captured right away for seperate billing and fast repair. No additional documentation required as the protocol gets signed directly in the App.",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Clean interface",
        icon: "how_to_reg",
        blurb:
          "Thanks to material design principles the interface is clean and easy to understand. Quickinspect.me does not require any additional trainings or skills. Coming with a great user experience QuickInspect.me ensures a great user acceptance resulting in the desired efficiency and motivation.",
        cols: "",
      },
      {
        id: 3,
        name: "Design inspections",
        icon: "list_alt",
        blurb:
          "You decide what to inspect, when to inspect and how to inspect. The specified admin can design inspections based on the inspection type and/or based on the group of assets. This way you can for example force an inspection of electrical equipment in accordance with DGUV V3 and on top of that additional requirements as you wish.",
        cols: "",
      },
      {
        id: 4,
        name: "Full inspection history",
        icon: "history",
        blurb:
          "Due to the asset and location based database you have the full overview of the assets lifecycle. Not only are all your regular inspections tracked but also every unplanned inspection or additional repair. With the Task management you keep track of the execution status of outstanding repairs and additional works.",
        cols: "",
        image: require("../assets/history.png"),
      },
      {
        id: 5,
        name: "Easy workflow",
        icon: "filter_3",
        blurb:
          "1. Choose asset from ToDoList or scan QR Code <br>2. Add inspection with all findings, additional Tasks and pictures as needed.<br>3. Sign and generate Protocol",
        cols: "",
        image: require("../assets/EasyWorkflow_03032020.png"),
        media: [
          {
            // For image
            thumb: require("../assets/history.png"),
            src: require("../assets/history.png"),
            caption: "", // Optional
          },
          {
            thumb: require("../assets/EasyWorkflow_03032020.png"),
            src: require("../assets/EasyWorkflow_03032020.png"),
          },
        ],
      },
    ],
  }),
  methods: {
    onLightBoxToggle(event) {
      !event ? (this.showlightbox = false) : "";
    },
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
<template>
  <HelloWorld></HelloWorld>
</template>

<script>
import HelloWorld from '../components/HelloWorld';

export default {
  components: {
    HelloWorld,
  },
/*   created () {
    AOS.init()
  }, */

};
</script>

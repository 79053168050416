import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackbar: {
        showsnackbar: false,
        snackcolor: 'green',
        snacktext: '',
        snacktime: 5,
        snackloading: false
    },
  },
  mutations: {
    updateSnackbar(state, snackbar) {
      state.snackbar = snackbar
    },
  },
  actions: {

  }
})

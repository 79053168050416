<template>
  <v-card :min-height="height">
    <v-card-title class="blue-grey darken-1 white--text justify-center">
      <span class="title">Contact us</span>
    </v-card-title>
    <v-card-text class="title mt-5 primary--text">
      <span class="d-flex justify-center mx-auto">
        You can use our contact form below to ask for a demonstration of our
        services or simply to get to know us. <br />
      </span>
      <span class="d-flex justify-center mx-auto">
        We are looking forward to receiving your message!
      </span>
    </v-card-text>
    <v-row no-gutters>
      <v-col cols="12" xs="12" md="6">
        <v-card class="ma-2" tile>
          <Contact />
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="6">
        <v-card class="ma-2" tile>
          <contactDetails />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Contact from "@/components/Contact.vue";
import contactDetails from "@/components/contactDetails.vue";

export default {
  components: { /* Expansion1 */ Contact, contactDetails },
  data: () => ({
    height: window.innerHeight - 200,
  }),
};
</script>
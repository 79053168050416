<template>
  <v-snackbar v-model="showsnackbar" :color="snackcolor" :timeout="snacktime">
    <div
      class="v-menu__content--active"
      style="display: none; z-index: 1000"
    ></div>
    {{ snacktext }}
    <v-btn
      outlined
      x-small
      icon
      dark
      text
      @click="showsnackbar = false"
      :loading="snackloading"
    >
      <v-icon> close </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    showsnackbar: true,
    snacktext:
      "We are using Google Analytics (cookies) only for our usage statistics. The data won't be shared.",
    snackcolor: "success",
    snacktime: -1,
    snackloading: null,
  }),

  computed: {},
  created: function () {
    this.$store.watch(
      (state) => state.snackbar,
      () => {
        this.showsnackbar = this.$store.state.snackbar.showsnackbar;
        this.snacktext = this.$store.state.snackbar.snacktext;
        this.snackcolor = this.$store.state.snackbar.snackcolor;
        this.$store.state.snackbar.snackloading
          ? (this.snacktime = 0)
          : (this.snacktime = this.$store.state.snackbar.snacktime);
        this.snackloading = this.$store.state.snackbar.snackloading;
      }
    );
  },
};
</script>



<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col v-for="card in cards" :key="card.id" :cols="size(card.cols)" class="ml-auto">
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="card.class ? card.class : 'justify-center headline primary--text font-weight-bold'"
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-text="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar v-if="!$vuetify.breakpoint.xs && card.icon" class="ma-1" size="150" tile>
                <v-icon size="70" color="primary">{{card.icon}}</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";

export default {
  components: { BackBtn },
  data: () => ({
    height: window.innerHeight - 200,
    cards: [
      {
        id: 1,
        name: "Protocol Included",
        icon: undefined,
        blurb:
          "Automatically generates a protocol for legislation compliance and billing - no paper required anymore. All additional tasks outside of the general inspection are pointed out seperately so that it can easily be charged extra.",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Design reference",
        icon: "palette",
        blurb:
          "The QuickInspect.me protocol picks up the same design principles: clean and easy. This allows your employees to compare the results easily and satisfy your customers without unnecessary information.",
        cols: "",
      },
      {
        id: 3,
        name: "Signature",
        icon: "create",
        blurb:
          "The inspector signs directly on any device which ensures bullet proof documentation of the performed inspections and all additional tasks. The reference to the inspector can be used for additional questions.",
        cols: "",
      },
      {
        id: 4,
        name: "Saved Protocol",
        icon: "save",
        blurb:
          "All data required for the protocol is synchronzied with our database so you will never miss a protocol again. No additional storage of data required and all changes are kept.",
        cols: "",
      },
      {
        id: 5,
        name: "PDF Output",
        icon: "picture_as_pdf",
        blurb:
          "The PDF protocol guarentees your clients the correctness of data. All additional Tasks are listed sperately for potential extra billing and we design the protocol to match your corporate design including your logo.",
        cols: "",
      },
    ],
  }),
  methods: {
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
import VueVideoPlayer from 'vue-video-player'

// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

Vue.use(VueVideoPlayer)

//import './fb'

Vue.config.productionTip = false

import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
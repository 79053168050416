<template>
  <div>
    <section>
      <v-img src="../assets/3.png" height="600">
        <v-container column align-center justify-end class="white--text">
          <v-row
            align="center"
            justify="center"
            no-gutters
            style="height: 500px"
          >
            <!-- <v-img
              contain
              class="justify-center"
              :src="require('../assets/QuickInspectLogoMain_transparent.png')"
              alt="quickinspect"
              :height="imageHeight"
            ></v-img> -->
            <!-- <svg id="Ebene_1" data-name="Ebene 1" viewBox="0 0 193.32 101.37"> -->
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="600"
              height="600"
              id="Ebene_1"
              data-name="Ebene 1"
              viewBox="0 0 193.32 101.37"
              preserveAspectRatio="xMidYMid meet"
            >
              <defs></defs>
              <circle class="tower-1" cx="42.33" cy="42.11" r="3.43" />
              <path
                class="cls-1"
                d="M61.74,88.73H66l4.76,9.08,1.8,4h.12c-.2-1.93-.53-4.49-.53-6.64V88.73h3.93V107H71.82l-4.76-9.1-1.81-4h-.11c.2,2,.53,4.46.53,6.61V107H61.74Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M79.18,104.68l2.37-2.83a7.26,7.26,0,0,0,4.48,1.9c1.72,0,2.57-.67,2.57-1.76s-1-1.51-2.63-2.17l-2.38-1a5.32,5.32,0,0,1-3.68-5c0-3,2.7-5.44,6.5-5.44a8.41,8.41,0,0,1,5.87,2.4l-2.08,2.6A5.91,5.91,0,0,0,86.41,92c-1.42,0-2.33.59-2.33,1.63s1.2,1.53,2.79,2.16l2.32,1c2.23.9,3.63,2.38,3.63,5,0,3-2.53,5.66-6.88,5.66A10,10,0,0,1,79.18,104.68Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M95.93,88.73h6.27c4,0,7.25,1.44,7.25,5.87s-3.27,6.19-7.14,6.19h-2.26V107H95.93Zm6.14,8.78c2.26,0,3.34-1,3.34-2.91S104.19,92,102,92h-1.9v5.5Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M112.61,88.73h11.44v3.46h-7.31v3.67H123v3.46h-6.22v4.2h7.59V107H112.61Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M127.1,98c0-6,4-9.57,8.61-9.57a7.74,7.74,0,0,1,5.49,2.37L139,93.41A4.58,4.58,0,0,0,135.8,92c-2.5,0-4.48,2.2-4.48,5.86s1.75,5.93,4.42,5.93a4.82,4.82,0,0,0,3.57-1.7l2.17,2.59a7.51,7.51,0,0,1-5.89,2.68C131,107.32,127.1,104.11,127.1,98Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M147.49,92.19h-5V88.73h14.17v3.46h-5V107h-4.12Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="lettersDot"
                d="M155.8,104.73a2.49,2.49,0,1,1,2.49,2.59A2.49,2.49,0,0,1,155.8,104.73Z"
                transform="translate(-6.14 -6.07)"
              />
              <ellipse
                class="turbine-2"
                cx="41.5"
                cy="44.03"
                rx="21.27"
                ry="19.08"
              />
              <ellipse
                class="turbine-1"
                cx="67.23"
                cy="67.2"
                rx="3.51"
                ry="13.22"
                transform="translate(-31.89 80.91) rotate(-57)"
              />
              <ellipse
                class="tower-2"
                cx="23.71"
                cy="52.62"
                rx="17.02"
                ry="3.92"
                transform="translate(-17.83 0.97) rotate(-13.58)"
              />
              <polyline
                class="tower-1"
                points="35.23 100.61 41.18 50.37 43.47 50.37 49.42 100.61"
              />
              <ellipse
                class="tower-2"
                cx="56.79"
                cy="23.49"
                rx="17.61"
                ry="3.48"
                transform="translate(7.86 61.54) rotate(-68.36)"
              />
              <path
                class="cls-2"
                d="M81.24,61.81V52h4.12V62.24c0,3.57,1.13,4.81,3.17,4.81s3.23-1.24,3.23-4.81V52h4v9.79c0,6.13-2.49,8.8-7.21,8.8S81.24,67.94,81.24,61.81Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-2"
                d="M100,52h4.12V70.27H100Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-2"
                d="M107.52,61.25c0-6,4-9.56,8.62-9.56a7.73,7.73,0,0,1,5.49,2.36l-2.18,2.65a4.58,4.58,0,0,0-3.22-1.45c-2.5,0-4.48,2.21-4.48,5.86s1.75,5.94,4.41,5.94a4.8,4.8,0,0,0,3.57-1.71l2.18,2.59A7.52,7.52,0,0,1,116,70.61C111.39,70.61,107.52,67.4,107.52,61.25Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-2"
                d="M124.68,52h4.13v7.46h.1L134.35,52h4.53l-5.52,7.29,6.54,11h-4.53l-4.43-7.71-2.13,2.82v4.89h-4.13Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M166.28,88.59h4.5l3,8.11c.36,1.08.68,2.25,1.05,3.37h.11c.36-1.12.67-2.29,1-3.37l2.87-8.11h4.5v18.25h-3.76v-6.7c0-1.77.34-4.43.53-6.2H180l-1.5,4.32-2.58,7h-2.29l-2.59-7-1.45-4.32h-.11c.19,1.77.52,4.43.52,6.2v6.7h-3.7Z"
                transform="translate(-6.14 -6.07)"
              />
              <path
                class="cls-1"
                d="M187.62,88.59h11.43v3.46h-7.3v3.67H198v3.46h-6.22v4.2h7.58v3.46H187.62Z"
                transform="translate(-6.14 -6.07)"
              />
            </svg>
          </v-row>

          <!-- <h1 class="white--text mb-2 display-1 text-center">Parallax Template</h1>
          <div class="subheading mb-4 text-center">Powered by Vuetify</div>-->
          <!-- <v-btn
            class="mt-12"
            color="blue lighten-2"
            dark
            large
            href="/pre-made-themes"
          >
            Get Started
          </v-btn> -->
        </v-container>
      </v-img>
    </section>
    <section class="">
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="display-1 primary--text font-weight-bold">
              A simple software solution for repetitive inspections
            </h2>
            <!-- <span class="subheading">
                Cras facilisis mi vitae nunc
            </span>-->
          </div>
        </v-flex>
        <v-flex xs12></v-flex>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <!-- class="fade-down" -->
              <v-row align="center" justify="space-around">
                <v-flex
                  xs12
                  md4
                  v-for="item in items"
                  :key="item.id"
                  class="pa-1"
                >
                  <v-fade-transition>
                    <v-expansion-panels popout v-model="item.expanded">
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          v-on:mouseover="item.size = 30"
                          v-on:mouseleave="
                            item.expanded === 0
                              ? (item.size = 30)
                              : (item.size = 0)
                          "
                        >
                          <template v-slot:actions>
                            <v-icon></v-icon>
                          </template>
                          <div outline :ripple="false">
                            <v-card flat height="150">
                              <v-card-text class="text-center">
                                <v-icon
                                  :size="50 + item.size"
                                  color="primary"
                                  >{{ item.icon }}</v-icon
                                >
                              </v-card-text>
                              <v-card-title
                                primary-title
                                class="layout justify-center"
                              >
                                <div
                                  class="
                                    headline
                                    font-weight-bold
                                    text-center
                                    primary--text
                                  "
                                >
                                  {{ item.title }}
                                </div>
                              </v-card-title>
                            </v-card>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card-text class="subtitle-1, primary--text">{{
                            item.text
                          }}</v-card-text>
                          <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn
                              icon
                              outlined
                              color="primary"
                              @click="routeDetails(item.to)"
                            >
                              <v-icon>chevron_right</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-fade-transition>
                </v-flex>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
    </section>
    <section>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <!-- class="fade-up" -->
            <v-row align="center" justify="space-around">
              <v-flex
                xs12
                md4
                v-for="item in items2"
                :key="item.id"
                class="pa-1"
              >
                <v-expansion-panels popout v-model="item.expanded">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      v-on:mouseover="item.size = 30"
                      v-on:mouseleave="
                        item.expanded === 0 ? (item.size = 30) : (item.size = 0)
                      "
                    >
                      <template v-slot:actions>
                        <v-icon color="error"></v-icon>
                      </template>
                      <div outline :ripple="false">
                        <v-card flat height="150">
                          <v-card-text class="text-center">
                            <v-icon :size="50 + item.size" color="primary">{{
                              item.icon
                            }}</v-icon>
                          </v-card-text>
                          <v-card-title
                            primary-title
                            class="layout justify-center"
                          >
                            <div
                              class="
                                headline
                                font-weight-bold
                                text-center
                                primary--text
                              "
                            >
                              {{ item.title }}
                            </div>
                          </v-card-title>
                        </v-card>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card-text class="subtitle-1, primary--text">{{
                        item.text
                      }}</v-card-text>
                      <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn
                          icon
                          outlined
                          color="primary"
                          @click="routeDetails(item.to)"
                        >
                          <v-icon>chevron_right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-layout column wrap class="my-12" align-center>
          <v-flex xs12 sm12 class="my-4">
            <video-player
              class="mb-8"
              ref="videoPlayer"
              :options="playerOptions"
              :playsinline="true"
              customEventName="customstatechangedeventname"
            >
            </video-player>

            <!-- <v-img :src="require('@/assets/QIFinalPromoWithMusic.MOV')"></v-img> -->
            <div class="text-center">
              <h2 class="display-1 primary--text font-weight-bold">
                Performing inspections has never been easier
              </h2>
            </div>
          </v-flex>
          <v-flex xs12>
            <Steps></Steps>
            <span class="h6 d-flex justify-center primary--text mt-5 mb-5"
              >We'll happily show you how easy it actually is :)
            </span>
          </v-flex>
          <v-btn outlined color="primary" @click="$router.push('contact')"
            >Request Demo</v-btn
          >
        </v-layout>
      </v-container>
    </section>
    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="display-1 primary--text font-weight-bold">Profiles</h2>
          </div>
        </v-flex>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="space-around" class="pa-1">
                <v-flex
                  xs12
                  md4
                  v-for="item in items3"
                  :key="item.id"
                  :class="'pricing' + item.id"
                >
                  <v-expansion-panels popout v-model="item.expanded">
                    <v-expansion-panel class="elevation-12">
                      <v-expansion-panel-header
                        v-on:mouseover="item.size = 30"
                        v-on:mouseleave="
                          item.expanded === 0
                            ? (item.size = 30)
                            : (item.size = 0)
                        "
                      >
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                        <div outline :ripple="false">
                          <v-card flat height="220">
                            <v-card-text class="text-center">
                              <v-icon :size="50 + item.size" color="primary">{{
                                item.icon
                              }}</v-icon>
                            </v-card-text>
                            <v-card-title
                              primary-title
                              class="layout justify-center"
                            >
                              <div
                                class="
                                  headline
                                  font-weight-bold
                                  text-center
                                  primary--text
                                "
                              >
                                {{ item.title }}
                                <h6
                                  class="
                                    font-weight-bold
                                    text-center
                                    primary--text
                                  "
                                >
                                  {{ item.price }}
                                </h6>
                                <h6
                                  class="
                                    font-weight-bold
                                    text-center
                                    green--text
                                  "
                                >
                                  free trial
                                </h6>
                              </div>
                            </v-card-title>
                          </v-card>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card-text class="subtitle-1, primary--text">{{
                          item.text
                        }}</v-card-text>
                        <v-card-actions>
                          <div class="flex-grow-1"></div>
                          <!-- <v-btn
                            outlined
                            color="primary"
                            @click="openNewWindow()"
                            >get started</v-btn
                          > -->
                          <v-btn
                            outlined
                            color="primary"
                            @click="openNewWindow('try')"
                            >try for free</v-btn
                          >
                        </v-card-actions>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
    </section>
  </div>
</template>


<script>
//import Expansion1 from './Expansion1.vue';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
import Steps from "./Steps.vue";
import firebase from "firebase/app";

export default {
  components: {
    /* Expansion1 */
    Steps,
  },
  data: () => ({
    playerOptions: {
      // videojs options
      muted: false,
      language: "en",
      /*  playbackRates: [0.7, 1.0, 1.5, 2.0], */
      fluid: true,
      sources: [
        {
          type: "video/mp4",
          src: require("@/assets/QIFinalPromoWithMusic.mp4"),
        },
      ],
      poster: require("@/assets/QuickInspectLogoMain.png"),
    },
    showCard: false,
    expand: false,
    size1: 0,
    size2: 0,
    size3: 0,
    size4: 0,
    size5: 0,
    size6: 0,
    items: [
      {
        id: 1,
        size: 0,
        expanded: null,
        to: "/offlineready",
        title: "Offline ready",
        icon: "cloud_done",
        text: "Automatically sync all your inspection results to the cloud no matter if you are online or offline. Mobile App gets you full offline support to inspect your assets without any risks of losing data.",
      },
      {
        id: 2,
        size: 0,
        to: "/quickinspections",
        expanded: null,
        title: "Quick inspections",
        icon: "speed",
        text: "Directly performed on the phone where deviations are captured right away for seperate billing and fast repair. No additional documentation required as the protocol gets signed directly in the App.",
      },
      {
        id: 3,
        size: 0,
        to: "/protocolincluded",
        expanded: null,
        title: "Protocol included",
        icon: "file_copy",
        text: "Automatically generates a protocol for legislation compliance and billing - no paper required anymore. All additional tasks outside of the general inspection are pointed out seperately so that it can easily be charged extra.",
      },
    ],
    items2: [
      {
        id: 1,
        size: 0,
        expanded: null,
        to: "/multipleplatforms",
        title: "Multiple platforms",
        icon: "important_devices",
        text: "All common devices and plattforms are supported with the latest OS. This includes Phones, Tablets, Computer, Notebooks & Convertibles. One application for all devices. Same look, same use, same experience across devices!",
      },
      {
        id: 2,
        size: 0,
        expanded: null,
        to: "/easyoverview",
        title: "Easy overview",
        icon: "insert_chart_outlined",
        text: "The simply structured database behind the scenes allows you to easily get an overview either using analytics tools via big query exports or the build in interface of the application.",
      },
      {
        id: 3,
        size: 0,
        expanded: null,
        to: "/designinspections",
        title: "Design inspections",
        icon: "ballot",
        text: "Easily design the inspections yourself: Give it a Name, decide how frequently the inspection has to be performed and define what needs to be inspected for which group of assets. Even inspection specific tools and materials can be choosen!",
      },
    ],
    items3: [
      {
        id: 1,
        size: 30,
        expanded: 0,
        to: "https://app.quickinspect.me/#/signup",
        title: "Basic",
        icon: "person",
        text: "A basic user can not edit permissions. The basic user is dependand on an admin user to setup the system.",
        /* price: "5€ / month", */
      },
      {
        id: 2,
        size: 30,
        expanded: 0,
        to: "https://app.quickinspect.me/#/signup",
        title: "Admin",
        icon: "person_add_alt_1",
        text: "An admin user can edit permissions of all users withing the same company. With that the admin user can administrate the system. Each company needs at least one admin user.",
        /* price: "7€ / month", */
      },
    ],
  }),
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "200px";
        default:
          return "300px";
      }
    },
  },
  methods: {
    startAnimation() {
      const tl = gsap.timeline({}).timeScale(0.7);
      tl.from(".cls-2", { duration: 1, scale: 1, opacity: 1, x: 200 });
      tl.from(
        ".lettersDot",
        { duration: 1, scale: 1, opacity: 1, x: 200 },
        "=+0"
      );
      tl.from(".cls-1", { duration: 1, scale: 1, opacity: 1, x: 200 }, "=-1");
      tl.startTime(0.5);
    },
    openNewWindow(type) {
      firebase.analytics().logEvent("clicked_tryforfree");
      type === "try"
        ? window.open("https://app.quickinspect.me/#/tryforfree")
        : window.open("https://app.quickinspect.me/#/register");
    },
    routeDetails(to) {
      this.$router.push(to);
    },
  },
  created() {},
  mounted() {
    /* gsap.fromTo(
      ".fade-up",
      {
        scrollTrigger: ".fade-up", // start the animation when ".box" enters the viewport (once)
        opacity: 0,
        duration: 2,
        y: -100,
      },
      {
        scrollTrigger: ".fade-up", // start the animation when ".box" enters the viewport (once)
        opacity: 1,
        duration: 2,
        y: 0,
      }
    ); */
    /* gsap.fromTo(
      ".fade-down",
      {
        scrollTrigger: ".fade-down", // start the animation when ".box" enters the viewport (once)
        opacity: 0,
        duration: 2,
        y: -100,
      },
      {
        scrollTrigger: ".fade-down", // start the animation when ".box" enters the viewport (once)
        opacity: 1,
        duration: 2,
        y: 0,
      }
    ); */
    /* gsap.from(".pricing1", {
      scrollTrigger: {
        trigger: ".pricing1",
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 0,
      duration: 2,
      x: -100,
    });
    gsap.from(".pricing2", {
      scrollTrigger: {
        trigger: ".pricing2",
      }, // start the animation when ".box" enters the viewport (once)
      opacity: 0,
      duration: 2,
      x: 100,
    }); */
    this.startAnimation();
  },
};
</script>


<style>
.tower-1,
.cls-1 {
  fill: #b3e5fc;
  stroke-width: 0.25px;
}

.cls-1,
.tower-1,
.turbine-2,
.cls-3 {
  stroke: #607d8b;
}

.cls-1,
.tower-1,
.tower-2,
.cls-4 {
  stroke-miterlimit: 10;
}
.turbine-1,
.lettersDot,
.cls-2 {
  fill: #607d8b;
}

.cls-3,
.turbine-2,
.tower-2,
.cls-4 {
  fill: none;
}
.turbine-2,
.cls-3 {
  stroke-width: 3px;
}
.tower-2,
.cls-4 {
  stroke: #b3e5fc;
  stroke-width: 2px;
}
</style>
<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col v-for="card in cards" :key="card.id" :cols="size(card.cols)" class="ml-auto">
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="card.class ? card.class : 'justify-center headline primary--text font-weight-bold'"
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-html="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar v-if="!$vuetify.breakpoint.xs && card.icon" class="ma-1" size="150" tile>
                <v-icon size="70" color="primary">{{card.icon}}</v-icon>
              </v-avatar>
            </div>
            <LightBox
              :showLightBox="true"
              :startAt="startAt"
              v-if="showlightbox && card.media"
              :media="card.media"
              @onClosed="onLightBoxToggle($event)"
            ></LightBox>
            <!-- :startAt="startAt"
              :media="card.media"
              v-if="showlightbox && card.media"
            @onClosed="onLightBoxToggle($event)"-->
            <v-img
              v-if="card.image"
              :src="card.image"
              contain
              height="250"
              @click="startAt = card.id -4 , showlightbox = !showlightbox"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";
import LightBox from "vue-image-lightbox";

export default {
  components: { BackBtn, LightBox },
  data: () => ({
    height: window.innerHeight - 200,
    showlightbox: false,
    startAt: 0,
    cards: [
      {
        id: 1,
        name: "Offline Ready",
        icon: undefined,
        blurb:
          "Automatically sync all your inspection results to the cloud no matter if you are online or offline. Mobile App gets you full offline support to inspect your assets without any risks of losing data.",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Automatic Caching",
        icon: "cached",
        blurb:
          "QuickInspect.me automatically caches your data on the fly. No synchronization prior to using the App required. Just login and start your inspection!",
        cols: "",
      },
      {
        id: 3,
        name: "Operate in the Cloud",
        icon: "cloud_queue",
        blurb:
          "All your data will be saved in the cloud and is accessible from anywhere on any device. Does not require any additional downloads or software. Regular backups and a 99% uptime Service Licence Agreement (SLA) ensures your operations.",
        cols: "",
      },
      {
        id: 4,
        name: "Upload Files offline",
        icon: "upload_files",
        blurb:
          "Load your pictures into the App when offline and sync your pictures when you have the best available connection. You can of course continue using the App whilst uploading the files. ",
        cols: "",
        image: require("../assets/FileSavedLocally_02032020.png"),
        media: [
          {
            // For image
            thumb: require("../assets/FileSavedLocally_02032020.png"),
            src: require("../assets/FileSavedLocally_02032020.png"),
            caption: "", // Optional
          },
          {
            thumb: require("../assets/UploadFilesOffline_02032020.png"),
            src: require("../assets/UploadFilesOffline_02032020.png"),
          },
        ],
      },
      {
        id: 5,
        name: "App Available",
        icon: "smartphone",
        blurb:
          "Installable Apps allow you to use the application no matter how good the connection is. Even in flight mode or without any signal at all in Offshore environments you are able to use the App.",
        cols: "",
        image: require("../assets/UploadFilesOffline_02032020.png"),
      },
    ],
  }),
  methods: {
    onLightBoxToggle(event) {
      !event ? (this.showlightbox = false) : "";
    },
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
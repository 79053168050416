import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/performance'
import "firebase/auth";
import 'firebase/analytics'

// Initialize Firebase
const config = {
  apiKey: "AIzaSyAdbENxZ4daRpHwxl1HoSshj7M8srO9V3Q",
  authDomain: "quickinspectwebpage.firebaseapp.com",
  databaseURL: "https://quickinspectwebpage.firebaseio.com",
  projectId: "quickinspectwebpage",
  storageBucket: "quickinspectwebpage.appspot.com",
  messagingSenderId: "475820488422",
  appId: "1:475820488422:web:609b1b4fb826e86cdbed27",
  measurementId: "G-93Q93V5ZP9"
};

firebase.initializeApp(config)

firebase.analytics()
const db = firebase.firestore()
const storage = firebase.storage()
const perf = firebase.performance();

db.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

export default db
export {storage, perf};




import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#607D8B',
        secondary: '#B3E5FC',//'#E1F5FE',//'#90CAF9', //BBDEFB
        accent: '#90CAF9',
        error: '#b71c1c',
        success: '#369763'
      },
      dark: {
        primary: '#607D8B',
        secondary: '#B3E5FC',//'#E1F5FE',//'#90CAF9', //BBDEFB
        accent: '#90CAF9',
        error: '#b71c1c',
        success: '#369763'
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});

<template>
  <v-timeline>
    <v-timeline-item
      v-for="(year, i) in years"
      :key="i"
      :color="year.color"
      small
    >
      <template v-slot:opposite v-if="!$vuetify.breakpoint.xs">
        <span
          :class="`headline font-weight-bold ${year.color}--text`"
          v-text="year.year"
        ></span>
        <!--  <v-card>
          <v-img height="200" contain :src="year.image"></v-img>
          <v-card-title>
           
          </v-card-title>
        </v-card> -->
      </template>
      <div class="py-4">
        <h2 :class="`headline font-weight-light mb-4 ${year.color}--text`">
          {{ year.title }}
        </h2>

        <div>
          <h4 class="primary--text">
            {{ year.text }}
          </h4>
        </div>
        <h5>
          <a v-if="year.link" :href="year.link" target="_blank">Show Example</a>
        </h5>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  data: () => ({
    years: [
      {
        color: "primary",
        year: "Step 1",
        title: "Choose asset from ToDoList or scan QR Code",
        text:
          "The ToDoList is automatically generated for you based on your location.",
        link:
          "https://firebasestorage.googleapis.com/v0/b/quickinspectwebpage.appspot.com/o/6.5-inch%20Screenshot%202.png?alt=media&token=10654dfd-fd74-4881-a31b-5834e5cfeb50",
      },
      {
        color: "primary",
        year: "Step 2",
        title: "Add inspection",
        text:
          "Simple questionaire that you can customize to fit your needs. Can easily follow legal requirements for inspections. You can add additional tasks and pictures.",
        link:
          "https://firebasestorage.googleapis.com/v0/b/quickinspectwebpage.appspot.com/o/6.5-inch%20Screenshot%203.png?alt=media&token=1786441b-991a-4a1e-b633-160d36f8f72a",
      },
      {
        color: "primary",
        year: "Step 3",
        title: "Sign on your device",
        text:
          "Once signed, the protocol will be automatically created. You can share it with customers and we store it as long as you need.",
      },
    ],
  }),
};
</script>
<template>
  <v-app>
    <v-app-bar color="white" app>
      <v-btn color="white" large to="/" text>
        <v-toolbar-title
          class="headline text-uppercase primary--text"
          @click="$route.name !== 'home' ? $router.push('/') : ''"
        >
          <span>Quick</span>
          <span class="font-weight-light">Inspect.me</span>
        </v-toolbar-title>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" href="https://app.quickinspect.me/">
        <!-- target="_blank" -->
        <span class="mr-2">Login</span>
        <v-icon>open_in_new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <transition :name="transitionName">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
      <SnackBar></SnackBar>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>


<script>
//import firebase from 'firebase/app'
import SnackBar from "@/components/utils/SnackBar";
import Footer from "@/components/utils/Footer";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");
//import 'aos/dist/aos.css'

export default {
  name: "App",
  components: {
    SnackBar,
    Footer,
  },
  data: () => ({
    transitionName: "",
  }),
  methods: {},
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName =
        toDepth < fromDepth
          ? "slide-x-transition"
          : "slide-x-reverse-transition";
    },
  },
  created() {
    /* firebase.analytics().logEvent('login'); */
    /* import('aos').then(AOS => AOS.init()); */
  },
};
</script>

<style scoped>
</style>
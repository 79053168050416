<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col
          v-for="card in cards"
          :key="card.id"
          :cols="size(card.cols)"
          class="ml-auto"
        >
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="
                    card.class
                      ? card.class
                      : 'justify-center headline primary--text font-weight-bold'
                  "
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-html="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar
                v-if="!$vuetify.breakpoint.xs && card.icon"
                class="ma-1"
                size="150"
                tile
              >
                <v-icon size="70" color="primary">{{ card.icon }}</v-icon>
              </v-avatar>
            </div>
            <LightBox
              :startAt="startAt"
              :media="card.media"
              v-if="showlightbox && card.media"
              @onClosed="onLightBoxToggle($event)"
            ></LightBox>
            <v-img
              v-if="card.image"
              :src="card.image"
              contain
              height="250"
              @click="(startAt = card.id - 2), (showlightbox = !showlightbox)"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";
import LightBox from "vue-image-lightbox";

export default {
  components: { BackBtn, LightBox },
  data: () => ({
    height: window.innerHeight - 200,
    startAt: 0,
    showlightbox: false,
    cards: [
      {
        id: 1,
        name: "Multiple Platforms",
        icon: undefined,
        blurb:
          "All common devices and plattforms are supported with the latest OS. This includes Phones, Tablets, Computer, Notebooks & Convertibles. </br> One application for all devices. Same look, same use, same experience across devices!",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Modern Devices",
        icon: "important_devices",
        blurb:
          "Our software solution gets tested against a huge variety of devices in so called Android Robo or XCTests (iOS). This digital test lab ensures theoretic compatibility with potentially used devices. We do not release an Update of Our App that has not passed this test.",
        cols: "",
        image: require("../assets/AndroidTestLap_02022020.png"),
      },
      {
        id: 3,
        name: "Performance",
        icon: "fast_forward",
        blurb:
          "Performance and usability is our first priority. By following the material design principles driven by Google we ensure a flawless use. This gets supported by the newest technology for single page applications resulting in remarkable performance: frequently used lighhouse tests show the highest performance results.",
        cols: "",
        image: require("../assets/ligthhouseTest_01032020.png"),
        media: [
          {
            // For image
            thumb: require("../assets/AndroidTestLap_02022020.png"),
            src: require("../assets/AndroidTestLap_02022020.png"),
            caption: "", // Optional
          },
          {
            thumb: require("../assets/ligthhouseTest_01032020.png"),
            src: require("../assets/ligthhouseTest_01032020.png"),
          },
        ],
      },
      {
        id: 4,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
      {
        id: 5,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
    ],
  }),
  methods: {
    onLightBoxToggle(event) {
      !event ? (this.showlightbox = false) : "";
    },
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col v-for="card in cards" :key="card.id" :cols="size(card.cols)" class="ml-auto">
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="card.class ? card.class : 'justify-center headline primary--text font-weight-bold'"
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-html="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar v-if="!$vuetify.breakpoint.xs && card.icon" class="ma-1" size="150" tile>
                <v-icon size="70" color="primary">{{card.icon}}</v-icon>
              </v-avatar>
            </div>
            <LightBox
              :startAt="startAt"
              :media="card.media"
              v-if="showlightbox && card.media"
              @onClosed="onLightBoxToggle($event)"
            ></LightBox>
            <v-img
              v-if="card.image"
              :src="card.image"
              contain
              height="250"
              @click="startAt = card.id -2 , showlightbox = !showlightbox"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";
import LightBox from "vue-image-lightbox";

export default {
  components: { BackBtn, LightBox },
  data: () => ({
    height: window.innerHeight - 200,
    showlightbox: false,
    startAt: 0,
    cards: [
      {
        id: 1,
        name: "Design Inspections",
        icon: undefined,
        blurb:
          "Easily design the inspections yourself: Give it a Name, decide how frequently the inspection has to be performed and define what needs to be inspected for which group of assets. Even inspection specific tools and materials can be choosen!",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Inspection Types",
        icon: "",
        blurb:
          "Create your own inspection for electrical components, safety equipment, tools, PPE and so on. You decide what and how to inspect, specify the checklist and the required material and tools.  The different inspections get supplemented by groups.",
        cols: "",
        image: require("../assets/InspectionDesignSmall.png"),
      },
      {
        id: 3,
        name: "Asset Groups",
        icon: "",
        blurb:
          "Your assets are put in groups for easier inspections and group specific checklists, required materials and tools.",
        cols: "",
        image: require("../assets/Groups.png"),
        media: [
          {
            // For image
            thumb: require("../assets/InspectionDesignSmall.png"),
            src: require("../assets/InspectionDesignSmall.png"),
          },
          {
            thumb: require("../assets/Groups.png"),
            src: require("../assets/Groups.png"),
          },
        ],
      },
      {
        id: 4,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
      {
        id: 5,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
    ],
  }),
  methods: {
    onLightBoxToggle(event) {
      !event ? (this.showlightbox = false) : "";
    },
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
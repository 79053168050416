<template>
  <v-card color="white" class="mx-auto" outlined max-width="400" height="490">
    <v-card-title
      class="justify-center headline primary--text font-weight-bold"
    >
      Contact Details
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" xs="12">
          <v-card flat>
            <p class="subtitle-1">
              <a href="mailto:info@quickinspect.me?" subject="New Request"
                >info@quickinspect.me</a
              >
            </p>
            <v-divider class="mb-5"></v-divider>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <p class="title font-weight-bold">Oliver Hoffmann</p>
                <p class="subtitle-1">
                  <a
                    href="mailto:oliver.hoffmann@quickinspect.me?"
                    subject="New Request"
                    >oliver.hoffmann@quickinspect.me</a
                  >
                </p>
                <p class="subtitle-1">+4917678461317</p>
              </div>
              <v-avatar :size="imageHeight" tile>
                <v-img src="../assets/logo.png"></v-img>
              </v-avatar>
            </div>
            <!-- <v-divider class="mb-5"></v-divider>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <p class="title font-weight-bold">Marton Ali</p>
                <p class="subtitle-1">
                  <a
                    href="mailto:marton.ali@quickinspect.me?"
                    subject="New Request"
                    >marton.ali@quickinspect.me</a
                  >
                </p>
                <p class="subtitle-1">+4917678461317</p>
              </div>
              <v-avatar :size="imageHeight" tile>
                <v-img src="../assets/logo.png"></v-img>
              </v-avatar>
            </div> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <p class="title font-weight-bold">Oliver Hoffmann</p>
            <p class="subtitle-1 font-weight-bold">
            <a href=mailto:oliver.hoffmann@quickinspect.me?
            subject="New Request">oliver.hoffmann@quickinspect.me</a></p>
            <p class="subtitle-1 font-weight-bold">+4917678461317</p>
        </v-col>
        </v-row>
    </v-card-text>
    <v-avatar class="ma-3"
                size="125"
                tile>
      <v-img :src="'https://cdn.vuetifyjs.com/images/cards/halcyon.png'"></v-img>
    </v-avatar> -->
    <v-divider></v-divider>
  </v-card>
</template>


<script>
//import db from '@/fb.js'
export default {
  data: () => ({}),
  methods: {},
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        default:
          return "150px";
      }
    },
  },
};
</script>
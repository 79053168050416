<template>
  <v-footer color="white" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="item in items"
        :key="item.id"
        color="primary lighten-1"
        text
        rounded
        class="my-2"
        @click="$router.push(item.link, () => {})"
        >{{ item.name }}</v-btn
      >
      <v-col class="py-4 text-center primary--text" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>QuickInspect.me</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        id: 1,
        name: "Home",
        link: "/",
      },
      {
        id: 2,
        name: "Contact",
        link: "/contact",
      },
      {
        id: 3,
        name: "About Us",
        link: "/aboutus",
      },
      {
        id: 4,
        name: "Impressum",
        link: "/impressum",
      },
      {
        id: 5,
        name: "Terms of Use",
        link: "/terms",
      },
      {
        id: 6,
        name: "Privacy Policy",
        link: "/privacy",
      },
    ],
  }),
};
</script>
import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import OfflineReady from './views/OfflineReady.vue'
import QuickInspections from './views/QuickInspections.vue'
import ProtocolIncluded from './views/ProtocolIncluded.vue'
import MultiplePlatforms from './views/MultiplePlatforms.vue'
import DesignInspections from './views/DesignInspections.vue'
import Impressum from './views/Impressum.vue'
import Terms from './views/Terms.vue'
import Privacy from './views/Privacy.vue'
import Contact from './views/Contact.vue'
import EasyOverview from './views/EasyOverview.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/offlineready/',
      component: OfflineReady
      //component: () => import('./views/OfflineReady.vue')
    },
    {
      path: '/quickinspections/',
      component: QuickInspections
      //component: () => import('./views/QuickInspections.vue')
    },
    {
      path: '/protocolincluded/',
      component: ProtocolIncluded
      //component: () => import('./views/ProtocolIncluded.vue')
    },
    {
      path: '/multipleplatforms/',
      component: MultiplePlatforms
      //component: () => import('./views/MultiplePlatforms.vue')
    },
    {
      path: '/easyoverview/',
      component: EasyOverview
      //component: () => import('./views/EasyOverview.vue')
    },
    {
      path: '/designinspections/',
      component: DesignInspections
      //component: () => import('./views/DesignInspections.vue')
    },
    {
      path: '/impressum/',
      component: Impressum
      //component: () => import('./views/Impressum.vue')
    },
    {
      path: '/terms/',
      component: Terms
      //component: () => import('./views/Impressum.vue')
    },
    {
      path: '/contact/',
      component: Contact
      //component: () => import('./views/Contact.vue')
    },
    {
      path: '/demo',
      component: Contact
      //component: () => import('./views/Contact.vue')
    },
    {
      path: '/aboutus/',
      component: () => import('./views/AboutUs.vue')
    },
    {
      path: '/privacy/',
      component: Privacy
      //component: () => import('./views/Contact.vue')
    },
  ],
  scrollBehavior( /* to, from, savedPosition */ ) {
    return {
      x: 0,
      y: 0
    }
  }
})
<template>
    <v-btn icon color="primary" @click="$router.go(-1)">
        <v-icon
        x-large    
        >
        chevron_left
        </v-icon>
    </v-btn> 
</template>

<script>
export default {

  data: () => ({

  }),
  methods: {

  },
  computed: {
      
  }
}
</script>
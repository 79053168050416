<template>
  <v-container fluid white>
    <v-card :min-height="height" elevation="6">
      <v-row no-gutters>
        <v-col
          v-for="card in cards"
          :key="card.id"
          :cols="size(card.cols)"
          class="ml-auto"
        >
          <v-card flat class="ma-3" :outlined="card.id === 1">
            <BackBtn v-if="card.id === 1"></BackBtn>
            <div class="d-flex justify-center">
              <div>
                <v-card-title
                  :class="
                    card.class
                      ? card.class
                      : 'justify-center headline primary--text font-weight-bold'
                  "
                  v-text="card.name"
                ></v-card-title>
                <v-card-text
                  class="subtitle-1 primary--text font-weight-medium"
                  v-html="card.blurb"
                ></v-card-text>
              </div>

              <v-avatar
                v-if="!$vuetify.breakpoint.xs && card.icon"
                class="ma-1"
                size="150"
                tile
              >
                <v-icon size="70" color="primary">{{ card.icon }}</v-icon>
              </v-avatar>
            </div>
            <LightBox
              :startAt="startAt"
              :media="card.media"
              v-if="showlightbox && card.media"
              @onClosed="onLightBoxToggle($event)"
            ></LightBox>
            <v-img
              v-if="card.image"
              :src="card.image"
              contain
              height="250"
              @click="(startAt = card.id - 2), (showlightbox = !showlightbox)"
            ></v-img>
            <div class="d-flex justify-center">
              <iframe
                v-if="card.id === 3"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/eyBK9nj-7AA"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import BackBtn from "@/components/utils/BackBtn.vue";
import LightBox from "vue-image-lightbox";

export default {
  components: { BackBtn, LightBox },
  data: () => ({
    height: window.innerHeight - 200,
    showlightbox: false,
    starAt: 0,
    cards: [
      {
        id: 1,
        name: "Easy Overview",
        icon: undefined,
        blurb:
          "The simply structured database behind the scenes allows you to easily get an overview either using analytics tools via big query exports or the build in interface of the application.",
        cols: 12,
        class: "display-1 justify-center primary--text font-weight-bold",
      },
      {
        id: 2,
        name: "Your Windfarm",
        icon: "",
        blurb: "Current status at first sight!",
        cols: "",
        image: require("../assets/Windfarm.png"),
        media: [
          {
            thumb: require("../assets/Windfarm.png"),
            src: require("../assets/Windfarm.png"),
          },
        ],
      },
      {
        id: 3,
        name: "Analysis",
        icon: "search",
        blurb:
          "Use Google Cloud platform with Big Query for data connections. If wanted, you can use your own Business Intelligence Tool such as Microsoft PowerBI.",
        cols: "",
      },
      {
        id: 4,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
      {
        id: 5,
        name: "",
        icon: "",
        blurb: "",
        cols: "",
      },
    ],
  }),
  methods: {
    onLightBoxToggle(event) {
      !event ? (this.showlightbox = false) : "";
    },
    size(cols) {
      return cols ? cols : this.$vuetify.breakpoint.xs ? 12 : 6;
    },
  },
  computed: {},
};
</script>
<template>
  <v-card color="white" class="mx-auto" outlined max-width="400" height="490">
    <v-card-title
      class="justify-center headline primary--text font-weight-bold"
    >
      Contact Form
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <v-row dense>
          <v-col cols="12" md="12" xs="12">
            <v-text-field
              color="primary"
              outlined
              v-model="name"
              :rules="nameRules"
              label="Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              color="primary"
              outlined
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              color="primary"
              outlined
              v-model="comment"
              label="Comment"
              rows="3"
              hide-details
              required
              :rules="required"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        outlined
        block
        color="primary"
        @click="submit()"
        :loading="loading"
        :disabled="!valid"
      >
        submit
        <v-icon> send </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import db from "@/fb.js";
export default {
  data: () => ({
    valid: false,
    name: "",
    comment: "",
    nameRules: [(v) => !!v || "Name is required"],
    required: [(v) => !!v || "Field is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    loading: false,
  }),
  methods: {
    submit() {
      if (this.valid) {
        this.loading = true;
        var to = [
          "oliver.hoffmann@quickinspect.me",
          "info@quickinspect.me",
          "marton.ali@quickinspect.me",
        ];
        db.collection("contactRequests")
          .add({
            sender: this.name,
            email: this.email,
            comment: this.comment,
          })
          .then((recordset) => {
            db.collection("mail")
              .add({
                to: to,
                template: {
                  name: "newContactRequest", //specifies the name of the template
                  data: {
                    username: "ada",
                    sender: this.name,
                    email: this.email,
                    title: "New Contact Request by " + this.name,
                    content: this.comment,
                    link:
                      "https://console.firebase.google.com/project/quickinspectwebpage/database/firestore/data~2FcontactRequests/" +
                      recordset.id,
                    recipients: to.length,
                  },
                },
              })
              .then(() => {
                this.$store.commit("updateSnackbar", {
                  showsnackbar: true,
                  snackcolor: "success",
                  snacktext:
                    "Contact request successfully send. We will come back to you soon.",
                });
                this.loading = false;
                this.name = "";
                this.email = "";
                this.comment = "";
                this.$refs.form.reset();
              })
              .catch((error) => {
                alert(error.message);
              });
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    },
  },
};
</script>